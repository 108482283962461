import Button from './components/Button';
import Title from './components/Title';

import getRelativeTime from './utils/getRelativeTime';
import getScreenSize from './utils/getScreenSize';
import getVideoId from './utils/getVideoId';

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { projects, tools, languages, memojis } from './constants';

export default function App() {
  const { t, i18n } = useTranslation();
  const { width } = getScreenSize();

  const [dropdown, setDropdown] = useState(false);

  const handleDropdownClick = (e) => {
    e.stopPropagation();
    setDropdown(prevState => !prevState);
  };

  const changeLanguage = (language) => {
    if (language === i18n.resolvedLanguage) return;
    i18n.changeLanguage(language);
    setDropdown(false);
  }

  const currentLanguage = languages[i18n.resolvedLanguage || 'en'] || languages.en;
  const languagesToDisplay = Object.keys(languages).filter(language => language !== i18n.resolvedLanguage);

  const scrollToContact = () => {
    const contact = document.getElementById('contact');
    if (!contact) return;

    contact.scrollIntoView({ behavior: 'smooth' });
  }

  const [memoji, setMemoji] = useState(memojis[0]);

  // precache images
  useEffect(() => {
    memojis.forEach(memoji => {
      const img = new Image();
      img.src = memoji.src;
    });
  }, []);

  const nextMemoji = useCallback(() => {
    const currentIndex = memojis.findIndex(m => m.alt === memoji.alt);
    const nextIndex = (currentIndex + 1) % memojis.length;

    if (width > 640) {
      const memojiElement = document.getElementById('memoji');
      if (!memojiElement) return;
  
      memojiElement.classList.add('scale-0');
  
      const timeout = setTimeout(() => {
        setMemoji(memojis[nextIndex]);
        memojiElement.classList.remove('scale-0');
      }, 150);
  
      return () => clearTimeout(timeout);
    }

    setMemoji(memojis[nextIndex]);
  }, [memoji.alt, width]);

  useEffect(() => {
    const interval = setInterval(() => {
      nextMemoji();
    }, 5000);

    return () => clearInterval(interval);
  }, [nextMemoji]);

  const [videoId, setVideoId] = useState('XdAvJC6aPO0');

  useEffect(() => {
    getVideoId().then(setVideoId);
  }, []);

  return (
    <div onClick={() => setDropdown(false)} className="space-y-16">
      {/* Navbar */}
      <nav className="w-full shadow-sm">
        <div className="flex px-8 sm:px-8 2xl:px-64 h-20 items-center justify-between from-transparent via-transparent-white to-transparent bg-gradient-to-r">
          {/* Left */}
          <h1 className="hover:cursor-pointer font-bold text-md">{t('me.name')}</h1>
          {/* Right */}
          <div className="space-x-4 flex justify-center items-center">
            <div onClick={handleDropdownClick}>
              <Button className="flex space-x-0 sm:space-x-3 sm:px-4 items-center justify-between">
                <img className="h-8 shadow" src={currentLanguage.flag} alt='lang_flag'></img>
                <img className={`h-0 sm:h-5 transform ${dropdown ? "rotate-180" : "rotate-0"} transition-transform`} src="./assets/ep_arrow-down-bold.png" alt='down_arrow'></img>
              </Button>
            </div>
            <div onClick={scrollToContact}>
              <Button className="flex items-center justify-center sm:px-6 space-x-2" gradient>
                { width < 640
                  ? (
                    <svg className="w-6 h-6 text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                      <path fillRule="evenodd" d="M3 5.983C3 4.888 3.895 4 5 4h14c1.105 0 2 .888 2 1.983v8.923a1.992 1.992 0 0 1-2 1.983h-6.6l-2.867 2.7c-.955.899-2.533.228-2.533-1.08v-1.62H5c-1.105 0-2-.888-2-1.983V5.983Zm5.706 3.809a1 1 0 1 0-1.412 1.417 1 1 0 1 0 1.412-1.417Zm2.585.002a1 1 0 1 1 .003 1.414 1 1 0 0 1-.003-1.414Zm5.415-.002a1 1 0 1 0-1.412 1.417 1 1 0 1 0 1.412-1.417Z" clipRule="evenodd"/>
                    </svg>
                  )
                  : <p>{t('contact.me')}</p> }
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full h-[1px] from-transparent via-white to-transparent bg-gradient-to-r opacity-[.15]"></div>
        {/* Dropdown */}
        <div className={`${dropdown ? "visible" : "invisible"} absolute top-[4.5rem] right-0 w-full sm:w-auto sm:right-[13.8rem] 2xl:right-[27.5rem] h-auto p-2 bg-secondary border border-outline shadow-md rounded-md flex flex-col justify-center space-y-4`}>
          {languagesToDisplay.map((language, index) => (
            <div key={index} onClick={() => changeLanguage(language)} className="hover:cursor-pointer flex space-x-3 items-center border-2 border-transparent hover:border-outline transition rounded-md px-4 py-2">
              <img className="h-8" src={languages[language].flag} alt='lang_flag'></img>
              <p>{t(languages[language].translation)}</p>
            </div>
          ))}
        </div>
      </nav>
      {/* Main */}
      <div className="w-full px-4 2xl:px-40 3xl:pl-[22rem] 3xl:pr-[22rem] space-y-28">
        {/* First section */}
        <section className="flex flex-col w-full items-center justify-center space-y-8">
          <div onMouseEnter={() => {
            if (width < 640) return;
            nextMemoji();
          }} className="flex items-center justify-center w-48 h-48 rounded-[48px] bg-secondary border border-outline shadow-md">
            <img id="memoji" className="transition duration-150 w-40" src={memoji.src} alt={memoji.alt}></img>
          </div>
          <div className="flex justify-center flex-col items-center text-center space-y-2">
            <h1 className="text-4xl sm:text-5xl font-bold text-gradient">Milhane CHIHEB</h1>
            <p className="text-lg text-tertiary w-full sm:w-[60%]">{t('me.short_description')}</p>
          </div>
          <div onClick={scrollToContact}>
            <Button gradient>{t("contact.me")}</Button>
          </div>
        </section>
        {/* Projects section */}
        <section className="flex flex-col w-full items-center justify-center space-y-8">
          <Title>{t('categories.projects')}</Title>
          <div className="w-full px-2 sm:px-16">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full">
                {projects.map((project, index) => (
                  <a key={index} href={project.link} target='_blank' rel="noreferrer" className="transition transform hover:scale-[1.01] hover:shadow-xl hover:shadow-transparent-white w-full p-6 flex flex-col bg-transparent-white border border-outline shadow-md rounded-2xl items-center justify-center space-y-4">
                    <img className="aspect-video object-cover rounded-lg shadow-sm" src={`./assets/${project.imageFolder}/0.png`} alt={project.title}></img>
                    <div className="w-full flex flex-col">
                      <h2 className="text-md font-regular text-tertiary">
                        {getRelativeTime(project.timestamp).toLowerCase()}
                      </h2>
                      <h2 className="text-xl sm:text-2xl font-semibold">{project.title}</h2>
                    </div>
                    <div className="w-full h-[1px] from-transparent via-white to-transparent bg-gradient-to-r opacity-[.4]"></div>
                    <div className="w-full flex flex-row items-center justify-start space-x-4">
                      {project.tools.map((tool, index) => (
                        <div key={index} className="flex items-center justify-center">
                          {tools[tool]}
                        </div>
                      ))}
                    </div>
                  </a>
                ))}
              </div>
          </div>
        </section>
        {/* Video section */}
        <section className="flex flex-col w-full items-center justify-center space-y-8">
          <Title>{t('categories.latestvideo')}</Title>
          {/* Youtube embed */}
          <div className="w-full px-2 sm:px-16">
            <div className="w-full h-full aspect-vide">
              <iframe className="aspect-video rounded-lg w-full" src={`https://www.youtube.com/embed/${videoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
          </div>
        </section>
        {/* Contact section */}
        <section id="contact" className="flex flex-col w-full items-center justify-center space-y-8">
          <Title>{t('categories.contact')}</Title>
          <div className="w-full px-2 sm:px-16">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full">
              <div className="w-full flex flex-col items-center justify-center space-y-4">
                <div className="flex items-center justify-center p-4 bg-transparent-white border border-outline rounded-xl">
                  <svg className="w-12 h-12 text-tertiary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path fill="currentColor" fillRule="evenodd" d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z" clipRule="evenodd"/>
                  </svg>
                </div>
                <div className="pb-4 flex flex-col text-center items-center justify-center">
                  <h1 className="text-xl font-semibold text-white">{t('contact.instagram')}</h1>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/milhanechiheb">
                    <h2 className="text-xl font-regular text-tertiary">@milhanechiheb</h2>
                  </a>
                </div>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/milhanechiheb">
                  <Button gradient className="px-20">{t('contact.sends.instagram')}</Button>
                </a>
              </div>
              <div className="w-full flex flex-col items-center justify-center space-y-4">
                <div className="flex items-center justify-center p-4 bg-transparent-white border border-outline rounded-xl">
                  <svg className="w-12 h-12 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M17 6h-2V5h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2h-.541A5.965 5.965 0 0 1 14 10v4a1 1 0 1 1-2 0v-4c0-2.206-1.794-4-4-4-.075 0-.148.012-.22.028C7.686 6.022 7.596 6 7.5 6A4.505 4.505 0 0 0 3 10.5V16a1 1 0 0 0 1 1h7v3a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-3h5a1 1 0 0 0 1-1v-6c0-2.206-1.794-4-4-4Zm-9 8.5H7a1 1 0 1 1 0-2h1a1 1 0 1 1 0 2Z"/>
                  </svg>
                </div>
                <div className="pb-4 flex flex-col text-center items-center justify-center">
                  <h1 className="text-xl font-semibold text-white">{t('contact.email')}</h1>
                  <a target="_blank" rel="noreferrer" href="mailto:hello@milhanechiheb.com">
                    <h2 className="text-xl font-regular text-tertiary">hello@milhanechiheb.com</h2>
                  </a>
                </div>
                <a target="_blank" rel="noreferrer" href="mailto:hello@milhanechiheb.com">
                  <Button gradient className="px-20">{t('contact.sends.email')}</Button>
                </a>
              </div>

            </div>
          </div>
        </section>
      </div>
      {/* Footer */}
      <footer className="w-full shadow-sm">
        <div className="w-full h-[1px] from-transparent via-white to-transparent bg-gradient-to-r opacity-[.15]"></div>
        <div className="flex h-20 items-center justify-center from-transparent via-transparent-white to-transparent bg-gradient-to-r">
          <p className="text-sm">{t('footer.copy')}</p>
        </div>
      </footer>
    </div>
  )
}